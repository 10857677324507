import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Category {
  id: number
  name: string
}

export interface CategoryOption {
  id: string
  name: string
}

export interface CategoryInfo {
  categories: Array<Category>;
  category: Category;
}

@Module
export default class CategoryModule
  extends VuexModule
  implements CategoryInfo
{
  categories = [] as Array<Category>;
  category_count = 0;
  category = {} as Category;

  get currentCategory(): Category {
    return this.category;
  }

  get currentCategories(): Array<Category> {
    return this.categories;
  }

  get currentCategoryCount() {
    return this.category_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_CATEGORY](category) {
    this.category = category
  }

  @Mutation
  [Mutations.SET_CATEGORIES](categories) {
    this.categories = categories
  }

  @Mutation
  [Mutations.SET_CATEGORY_COUNT](category_count) {
    this.category_count = category_count
  }

  @Action
  [Actions.GET_CATEGORIES](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/categories", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CATEGORIES,
            data["categories"]
          );
          this.context.commit(
            Mutations.SET_CATEGORY_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CATEGORY](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/categories/${id}`)
        .then(({ data }) => {
          console.log(data["category"])
          this.context.commit(
            Mutations.SET_CATEGORY,
            data["category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_CATEGORY](credentials) {
    ApiService.setHeader()

    const params = {
      category: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/categories`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CATEGORY,
            data["category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_CATEGORY](credentials) {
    ApiService.setHeader()

    const params = {
      category: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/categories/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CATEGORY,
            data["category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_CATEGORY](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/categories/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
