import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"
import { BoxType } from "@/store/modules/BoxInfoModule"

export interface Product {
  sku: string
  name: string
  image: string
  image_url: string
  price: number
  income_price: number
  recycle_price: number
  link: string
  brand_id: number
  ip_id: number
  category_id: number
  is_presale: boolean
  arrival_date: Date
  stock: number
  is_on_sale: boolean
  is_point: boolean
  is_bundle_gift: boolean
  is_card: boolean
  auto_open: boolean
  need_shipping_fee: boolean
  product_images_attributes: Array<any>
  product_gifts_attributes: Array<ProductGift>
  recycle_type: string
  product_type: string
}
export interface ProductGift {
  gift_product_id: number,
  product: string,
  brand: string,
  price: number,
  recycle_price: number,
  quantity: number,
  sorting: number
}

export interface ProductInfo {
  products: Array<Product>
  all_products: Array<Product>
  product: Product
}

export interface ProductBrandOption {
  key: string,
  value: string
}

export interface ProductIpOption {
  key: string,
  value: string
}

export interface ProductOption {
  key: string,
  value: string
}

@Module
export default class ProductModule
  extends VuexModule
  implements ProductInfo
{
  products = [] as Array<Product>
  all_products = [] as Array<Product>
  product_count = 0
  product = {} as Product
  product_brand_options = [] as Array<ProductOption>
  category_options = [] as Array<ProductOption>
  product_ip_options = [] as Array<ProductOption>
  sale_type_options = [] as Array<ProductOption>
  product_recycle_type_options = [] as Array<ProductOption>
  download_products = {} as any
  box_products = [] as Array<BoxType>
  relative_box_infos = [] as any

  get currentProduct(): Product {
    return this.product
  }

  get currentProducts(): Array<Product> {
    return this.products
  }

  get currentAllProducts(): Array<Product> {
    return this.all_products
  }

  get currentProductBrandOptions(): Array<ProductOption> {
    return this.product_brand_options
  }

  get currentCategoryOptions(): Array<ProductOption> {
    return this.category_options
  }

  get currentProductIpOptions(): Array<ProductOption> {
    return this.product_ip_options
  }

  get currentSaleTypeOptions(): Array<ProductOption> {
    return this.sale_type_options
  }

  get currentProductRecycleTypeOptions(): Array<ProductOption> {
    return this.product_recycle_type_options
  }

  get currentProductCount() {
    return this.product_count
  }

  get currentDownloadProducts(){
    return this.download_products
  }

  get currentBoxProducts(){
    return this.box_products
  }

  get currentRelativeBoxInfos(){
    return this.relative_box_infos
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_PRODUCT](product) {
    this.product = product
  }

  @Mutation
  [Mutations.SET_PRODUCTS](products) {
    this.products = products
  }

  @Mutation
  [Mutations.SET_ALL_PRODUCTS](all_products) {
    this.all_products = all_products
  }

  @Mutation
  [Mutations.SET_DOWNLOAD_PRODUCTS](download_products) {
    this.download_products = download_products
  }

  @Mutation
  [Mutations.SET_PRODUCT_BRAND_OPTIONS](product_brand_options) {
    this.product_brand_options = product_brand_options
  }

  @Mutation
  [Mutations.SET_CATEGORY_OPTIONS](category_options) {
    this.category_options = category_options
  }

  @Mutation
  [Mutations.SET_PRODUCT_IP_OPTIONS](product_ip_options) {
    this.product_ip_options = product_ip_options
  }

  @Mutation
  [Mutations.SET_SALE_TYPE_OPTIONS](sale_type_options) {
    this.sale_type_options = sale_type_options
  }

  @Mutation
  [Mutations.SET_PRODUCT_RECYCLE_TYPE_OPTIONS](product_recycle_type_options) {
    this.product_recycle_type_options = product_recycle_type_options
  }

  @Mutation
  [Mutations.SET_PRODUCT_COUNT](product_count) {
    this.product_count = product_count
  }

  @Mutation
  [Mutations.SET_BOX_PRODUCTS](box_products) {
    this.box_products = box_products
  }

  @Mutation
  [Mutations.SET_RELATIVE_BOX_INFOS](relative_box_infos) {
    this.relative_box_infos = relative_box_infos
  }

  @Action
  [Actions.GET_PRODUCTS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products", queryString)
        .then(({ data }) => {
          console.log(data["products"])
          this.context.commit(
            Mutations.SET_PRODUCTS,
            data["products"]
          );
          this.context.commit(
            Mutations.SET_PRODUCT_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ALL_PRODUCTS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/all", queryString)
        .then(({ data }) => {
          console.log(data["products"])
          this.context.commit(
            Mutations.SET_ALL_PRODUCTS,
            data["products"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRODUCT_BRAND_OPTIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/get_product_brand_options", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_BRAND_OPTIONS,
            data["product_brand_options"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CATEGORY_OPTIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/get_category_options", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CATEGORY_OPTIONS,
            data["category_options"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRODUCT_IP_OPTIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/get_product_ip_options", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_IP_OPTIONS,
            data["product_ip_options"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SALE_TYPE_OPTIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/get_sale_type_options", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SALE_TYPE_OPTIONS,
            data["sale_type_options"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRODUCT_RECYCLE_TYPE_OPTIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/get_product_recycle_type_options", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_RECYCLE_TYPE_OPTIONS,
            data["product_recycle_type_options"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRODUCT](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/products/${id}`)
        .then(({ data }) => {
          console.log(data["product"]);
          this.context.commit(
            Mutations.SET_PRODUCT,
            data["product"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DOWNLOAD_PRODUCTS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/download_excel", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DOWNLOAD_PRODUCTS,
            data
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CREATE_PRODUCT](credentials) {
    ApiService.setHeader()

    const params = {
      product: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/products`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT,
            data["product"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PRODUCT](credentials) {
    ApiService.setHeader()

    const params = {
      product: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/products/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT,
            data["product"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_PRODUCTS](params) {
    ApiService.setHeader()

    const formData = new FormData()
    const file = params['file']
    formData.append('file', file)

    return new Promise<void>((resolve, reject) => {
      ApiService.post_multipart(`admin/products/upload`, formData)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PRODUCT](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/products/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.ABANDON_PRODUCT](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/products/${id}/abandon`, {})
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_BOX_PRODUCTS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/products/box_products")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_PRODUCTS,
            data["box_products"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_RELATIVE_BOX_INFOS](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/products/${id}/relative_box_infos`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_RELATIVE_BOX_INFOS,
            data["box_infos"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
