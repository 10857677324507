import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/products",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/dashboard/index.vue"),
      },
      {
        path: "/card_records",
        name: "card_records",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/card_records/index.vue"),
      },
      {
        path: "/gift_records",
        name: "gift_records",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/gift_records/index.vue"),
      },
      {
        path: "/gift_records/new",
        name: "gift_record_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/gift_records/new.vue"),
      },
      {
        path: "/box_records",
        name: "all_box_records",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_records/index.vue"),
      },
      {
        path: "/water_items",
        name: "water_items",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/water_items/index.vue"),
      },
      {
        path: "/loser_items",
        name: "loser_items",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/loser_items/index.vue"),
      },
      {
        path: "/product_brands",
        name: "product_brands",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/product_brands/index.vue"),
      },
      {
        path: "/product_brands/new",
        name: "product_brand_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/product_brands/new.vue"),
      },
      {
        path: "/product_brands/:id/edit/",
        name: "product_brand_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/product_brands/edit.vue"),
      },
      {
        path: "/ips",
        name: "ips",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/ips/index.vue"),
      },
      {
        path: "/ips/new",
        name: "ip_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/ips/new.vue"),
      },
      {
        path: "/ips/:id/edit/",
        name: "ip_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/ips/edit.vue"),
      },{
        path: "/compounds",
        name: "compounds",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/compounds/index.vue"),
      },
      {
        path: "/compounds/new",
        name: "compound_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/compounds/new.vue"),
      },
      {
        path: "/compounds/:id/edit/",
        name: "compound_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/compounds/edit.vue"),
      },
      {
        path: "/banners",
        name: "banners",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/banners/index.vue"),
      },
      {
        path: "/products",
        name: "products",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/products/index.vue"),
      },
      {
        path: "/products/new",
        name: "product_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/products/new.vue"),
      },
      {
        path: "/products/:id/edit/",
        name: "product_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: false
        },
        component: () => import("@/views/apps/products/edit.vue"),
      },
      {
        path: "/events",
        name: "events",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/events/index.vue"),
      },
      {
        path: "/events/new",
        name: "event_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/events/new.vue"),
      },
      {
        path: "/events/edit",
        name: "event_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/events/edit.vue"),
      },
      {
        path: "/shipping_orders",
        name: "shipping_orders",
        meta: {
          roles: ['admin', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/shipping_orders/index.vue"),
      },
      {
        path: "/shipping_orders/:id/edit/",
        name: "shipping_order_edit",
        meta: {
          roles: ['admin', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/shipping_orders/edit.vue"),
      },
      {
        path: "/members",
        name: "members",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/members/index.vue"),
      },
      {
        path: "/members/:id/edit/",
        name: "member_edit",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/members/edit.vue"),
      },
      {
        path: "/members/:id/report_days",
        name: "member_report_days",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/members/report_days.vue"),
      },
      {
        path: "/payment_records",
        name: "payment_records",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/payment_records/index.vue"),
      },
      {
        path: "/add_balance_records",
        name: "add_balance_records",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_balance_records/index.vue"),
      },
      {
        path: "/add_balance_records/new",
        name: "add_balance_record_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_balance_records/new.vue"),
      },
      {
        path: "/remove_balance_records",
        name: "remove_balance_records",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/remove_balance_records/index.vue"),
      },
      {
        path: "/remove_balance_records/new",
        name: "remove_balance_record_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/remove_balance_records/new.vue"),
      },
      {
        path: "/add_point_records",
        name: "add_point_records",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_point_records/index.vue"),
      },
      {
        path: "/add_point_records/new",
        name: "add_point_record_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_point_records/new.vue"),
      },
      {
        path: "/add_extra_point_records",
        name: "add_extra_point_records",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_extra_point_records/index.vue"),
      },
      {
        path: "/add_extra_point_records/new",
        name: "add_extra_point_record_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/add_extra_point_records/new.vue"),
      },
      {
        path: "/box_infos",
        name: "box_infos",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_infos/index.vue"),
      },
      {
        path: "/box_infos/new",
        name: "box_info_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_infos/new.vue"),
      },
      {
        path: "/box_infos/:id/edit/",
        name: "box_info_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_infos/edit.vue"),
      },
      {
        path: "/box_infos/:id/boxes",
        name: "boxes",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/boxes/index.vue"),
      },
      {
        path: "/box_infos/:box_info_id/boxes/:id/box_records",
        name: "box_records",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_records/index.vue"),
      },
      {
        path: "/box_categories",
        name: "box_categories",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_categories/index.vue"),
      },
      {
        path: "/box_categories/new",
        name: "box_category_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_categories/new.vue"),
      },
      {
        path: "/box_categories/:id/edit/",
        name: "box_category_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/box_categories/edit.vue"),
      },
      {
        path: "/categories",
        name: "categories",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/categories/index.vue"),
      },
      {
        path: "/categories/new",
        name: "category_new",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/categories/new.vue"),
      },
      {
        path: "/categories/:id/edit/",
        name: "category_edit",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/categories/edit.vue"),
      },
      // {
      //   path: "/users",
      //   name: "users",
      //   meta: {
      //     roles: ['admin']
      //   },
      //   component: () => import("@/views/apps/users/index.vue"),
      // },
      {
        path: "/shop_banners",
        name: "shop_banners",
        meta: {
          roles: ['admin', 'operation', 'goods'],
          keepAlive: true
        },
        component: () => import("@/views/apps/shop_banners/index.vue"),
      },
      {
        path: "/user_banners",
        name: "user_banners",
        meta: {
          roles: ['admin', 'operation'],
          keepAlive: true
        },
        component: () => import("@/views/apps/user_banners/index.vue"),
      },
      {
        path: "/admins",
        name: "admins",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/admins/index.vue"),
      },
      {
        path: "/admins/new",
        name: "admin_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/admins/new.vue"),
      },
      {
        path: "/admins/:id/edit/",
        name: "admin_edit",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/admins/edit.vue"),
      },
      {
        path: "/distributions",
        name: "distributions",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/distributions/index.vue"),
      },
      {
        path: "/distributions/new",
        name: "distribution_new",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/distributions/new.vue"),
      },
      {
        path: "/distributions/:id/edit",
        name: "distribution_edit",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/distributions/edit.vue"),
      },
      {
        path: "/distributions/:distribution_id/users",
        name: "distribution_users",
        meta: {
          roles: ['admin'],
          keepAlive: true
        },
        component: () => import("@/views/apps/distribution_users/index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // console.log(to)
  // console.log(to.meta.roles)
  // console.log(store.getters.currentUser)
  // console.log(store.getters.currentUser.role)

  if (to.meta.roles) {
    if (store.getters.currentUser) {
      if((to.meta.roles as Array<string>).includes(store.getters.currentUser.role)) {
        next()
      } else {
        next({ path: "/404" })
      }
    } else {
      next()
    }
  } else {
    next()
  }

  // console.log("router")
  // console.log(store.getters.currentUser)
  // next()

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
